<template>
  <v-container fluid v-resize="resizeTable">
    <v-card flat>
      <v-card-title class="pb-0">
        {{ $t('Sites') }}
        <v-spacer />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="
                ($event) => {
                  isSearchPanelOpen = !isSearchPanelOpen;
                  resizeTable();
                  $event.target.blur();
                }
              "
              >{{ isSearchPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
            >
          </template>
          <div class="filter-tooltip">
            {{ isSearchPanelOpen ? $t('Hide filter') : $t('Show filter') }}
          </div>
        </v-tooltip>
      </v-card-title>
      <v-flex>
        <v-card-text>
          <div id="searchPanel" v-show="isSearchPanelOpen">
            <v-autocomplete
              clearable
              auto-select-first
              v-model="venueFilter"
              :label="$t('Venue name')"
              :items="venues"
              item-text="name"
              item-value="id"
              single-line
              return-object
            ></v-autocomplete>
            <gli-text-field
              clearable
              v-model="filter.name"
              :label="$t('Name')"
              single-line
            ></gli-text-field>
          </div>

          <v-data-table
            :headers="headers.map((item) => ({ ...item, text: $t(item.text) }))"
            fixed-header
            :items="filteredSites"
            item-key="id"
            :loading="isLoading"
            :options.sync="pagination"
          >
            <template v-slot:item.name="{ item }">
              <router-link :to="{ name: 'Draw', params: { siteId: item.id } }">
                <gli-label
                  v-model="item.name"
                  width="300px"
                  style="text-decoration-line:underline"
                ></gli-label>
              </router-link>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-1"
                    color="primary"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="exportSite(item)"
                  >
                    <v-icon>mdi-update</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Update routing network') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-1"
                    color="primary"
                    fab
                    x-small
                    v-on="on"
                    @click="duplicateSite(item.id)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Duplicate site') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-1"
                    color="error"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="deleteSite(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-flex>

      <v-card-actions class="mr-2">
        <v-spacer></v-spacer>
        <gli-editor-button color="primary" @click="openDialog">{{
          $t('New site')
        }}</gli-editor-button>
      </v-card-actions>
    </v-card>

    <site-dialog v-if="showDialog"></site-dialog>

    <v-dialog v-model="siteExportResponseDialog" :max-width="300">
      <v-card>
        <v-card-title>
          <v-icon large :color="siteExportResponseSuccess ? 'primary' : 'error'" class="mr-3">
            {{ siteExportResponseSuccess ? 'mdi-information-outline' : 'mdi-alert-outline' }}
          </v-icon>
          {{ $t('Update routing network') }}
        </v-card-title>
        <v-card-text>{{ siteExportResponseDialogText }}<br /></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="siteExportResponseDialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
::v-deep .row-error {
  color: var(--v-error-base);
}

.select-filter.v-icon:focus::after {
  opacity: 0 !important;
}

#searchPanel {
  padding: 10px;
  ::v-deep .v-label {
    font-size: 0.9em;
  }
  .col-4,
  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.load {
  color: var(--v-warning-darken3);
}

.full {
  color: var(--v-success-darken3);
}

.v-application .v-data-table .v-data-table__expanded .v-data-table.table-details {
  background: var(--v-background-darken1);
  padding: 6px;
  border-radius: 0;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 8px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import resizeTable from '../mixins/resizeTable';
import ApiService from '../services/ApiService';

const emptyFilter = {
  name: ''
};

export default {
  name: 'Sites',

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: '',
          value: 'actions',
          width: 125,
          sortable: false,
          align: 'end'
        }
      ],
      isSearchPanelOpen: true,
      filter: JSON.parse(JSON.stringify(emptyFilter)),
      venueFilter: null,
      pagination: {
        page: 1
      },
      showDialog: false,
      siteExportResponseDialog: false,
      siteExportResponseSuccess: undefined,
      siteExportResponseDialogText: ''
    };
  },

  async mounted() {
    this.showDialog = false;
    await this.getAll();
    await this.getAllVenues();
  },

  async beforeDestroy() {
    this.checkChanges = false;
  },

  computed: {
    ...mapGetters('site', ['sites', 'isLoading', 'editedSite']),
    ...mapGetters('venue', ['venues']),
    filteredSites() {
      let filtered = this.sites;

      if (this.venueFilter) {
        const siteIds = this.venueFilter.sites.map((site) => site.id);
        filtered = filtered.filter((site) => siteIds.includes(site.id));
      }

      for (const [key, value] of Object.entries(this.filter)) {
        if (!value) {
          continue;
        }

        // v-select filter
        if (Array.isArray(value)) {
          if (value.length) {
            filtered = filtered.filter((freight) => value.includes(freight[key]));
          }
        } else {
          // gli-text-field filter
          const regexp = new RegExp(value, 'i');
          filtered = filtered.filter((item) => item[key].match(regexp));
        }
      }

      return filtered;
    }
  },

  watch: {
    filter: {
      handler() {
        this.pagination.page = 1;
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('alert', ['error']),
    ...mapActions('site', ['getAll']),
    ...mapActions('site', {
      addNewSite: 'addNew',
      deleteSite: 'delete',
      duplicateSite: 'duplicate'
    }),
    ...mapActions('venue', { getAllVenues: 'getAll' }),
    async openDialog() {
      if (this.$route.name !== 'Home') {
        await this.$router.push('/');
      }
      this.addNewSite();

      this.showDialog = true;
    },
    async exportSite(selectedSite) {
      const confirmText =
        '<i>' +
        this.$t('Selected site') +
        ':<br><b>' +
        selectedSite.name +
        '</i></b><br><br><i>' +
        this.$t('Only the saved elements of this site will be updated!') +
        '</i><br>' +
        this.$t('Are you sure you want to update the routing network for this site?');

      const answer = await this.$confirm(confirmText, {
        title: this.$t('Update routing network'),
        icon: 'mdi-help-circle',
        color: 'primary',
        width: 460,
        buttonTrueText: this.$t('Update'),
        buttonFalseText: this.$t('Cancel')
      });
      if (!answer) {
        return;
      }

      const siteIds = [selectedSite.id];
      const exportResp = await ApiService.exportData({ siteIds }).catch(() => {
        this.siteExportResponseSuccess = false;
        this.siteExportResponseDialogText = this.$t('Error while updating routing network.');
      });
      if (exportResp?.status && exportResp.status === 200) {
        this.siteExportResponseSuccess = true;
        this.siteExportResponseDialogText = this.$t('Routing network updated successfully.');
      }

      this.siteExportResponseDialog = true;
    }
  },

  mixins: [resizeTable],

  components: {
    SiteDialog: () => import('../components/SiteDialog')
  }
};
</script>
